import React from 'react';
import Layout from '../components/Layout';
import EventSlides from '../components/eventSlideshow';

const Events = () => (
  <div>
    <Layout>
    <div className={"slideshow"}><EventSlides /></div>
      <div className={"content"}>
        <ul>
          <li>
            <h1>Events</h1>
            <p>
              
            </p>
          </li>
        </ul>
      </div>
    </Layout>
  </div>
);

export default Events;
